import React, { useEffect, useState, useRef } from 'react';
import './Home.css';
import Carousel from './utils/Carousel';
import CustomAccordian from './utils/Accordion';
import VerticalCarousel from './utils/VerticalCarousel';
import { GlobalMethods } from '../services/GlobalMethods';
import SendEmail from './utils/SendEmail';
import MetaTags from './utils/MetaTags';

function Home() {
  const [play, setPlay] = useState("true");
  const [play1, setPlay1] = useState("true");
  const methods = new GlobalMethods();
  if (GlobalMethods.current_sec == "error" ||
    GlobalMethods.current_sec == "error-page") {
    GlobalMethods.current_sec = "hero-section";
  }
  useEffect(() => {
    setTimeout(() => {
      methods.snapTo(GlobalMethods.current_sec)
    }, 500);
  }, [GlobalMethods.current_sec])
  
  // video resolution on screen size 
  const [videoSrc, setVideoSrc] = useState('../assets/fitness-intro-low.mp4');
  const updateVideoSrc = () => {
    if (window.innerWidth >= 1440) {
      setVideoSrc('../assets/fitness-intro-hd.mp4');
    } else {
      setVideoSrc('../assets/fitness-intro-low.mp4');
    }
  };
  useEffect(() => {
    updateVideoSrc(); // Set on initial load
    window.addEventListener('resize', updateVideoSrc); // Update on window resize
    return () => window.removeEventListener('resize', updateVideoSrc); // Cleanup event listener
  },[]);

  // handling thumbnail of video and controls 
  const [isPlaying, setIsPlaying] = useState(false);
  const videoRef = useRef(null);

  const handleThumbnailClick = () => {
    setIsPlaying(true);
    if (videoRef.current) {
      videoRef.current.play();  // Play the video
    }
  };

  return (
    <>
      <MetaTags text="MyFitnessCoach | My Fitness Coach" />
      <div id="custom-scroll-spy" className="px-3">
        <section id="hero-section" className='hero-sec-homepg'>
          <div className='hero-wraper d-flex flex-row align-items-center'>
            <div className='hero-left col-md-12 d-flex flex-column  ps-2'>
              <div className='hero-left-top d-flex flex-column rg d-flex flex-column '>
                <div className='hero-title d-flex flex-column position-relative'>
                  <h1 className='app-titleh d-flex flex-column align-items-start gap-1 m-0'>Introducing MyFitnessCoach
                  <svg width="171" height="7" viewBox="0 0 171 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M170.314 3.70294C170.488 3.52938 170.488 3.24797 170.314 3.0744L167.486 0.245975C167.312 0.0724087 167.031 0.0724087 166.857 0.245975C166.684 0.419541 166.684 0.700948 166.857 0.874514L169.371 3.38867L166.857 5.90283C166.684 6.0764 166.684 6.3578 166.857 6.53137C167.031 6.70494 167.312 6.70494 167.486 6.53137L170.314 3.70294ZM0 3.83312H170V2.94423H0V3.83312Z" fill="#1A5EC3"/>
                  </svg>


                  </h1>
                  <h3 className='hero-heading mt-3'>An <span className='hero-heading-blue'> All-in-One</span></h3>
                  <h3 className='hero-heading d-flex flex-row flex-wrap'>Fitness Solution &nbsp;
                    {/* <span className='text-logo d-flex flex-column'>
                      <h3 className='hero-sub-heading'>lifestyle</h3>
                      <img src="../assets/text-logo.svg" alt="underline" className='position-absolute bottom-0' />
                    </span> */}
                  </h3>
                </div>
                <div className='hero-desc-wraper'>
                  <h4 className='hero-description plan-txt-md'>
                    My Fitness Coach is your ultimate destination for cutting-edge workouts and healthy diet plans.
                  </h4>
                </div>
                <div className='hero-buttons-applink d-flex flex-row cg pt-4 '>
                  <a className=' d-flex flex-row align-items-center' href='https://play.google.com/store/apps/details?id=com.myfitnesscoach' target='_blank'>
                    <img src="../assets/google-store-btn.svg" alt='Get it on Google Play Store' />
                  </a>
                  <a className=' d-flex flex-row align-items-center' href='https://apps.apple.com/pk/app/myfitnesscoach-diet-workout/id1670136195' target='_blank'>
                    <img src="../assets/app-store-btn.svg" alt='Get it on App store' />
                  </a>
                </div>
                <div className='download-stats d-flex align-items-center gap-3 mt-4'>
                  <img src='../assets/app-downloaders.png' />
                  <div className='d-flex flex-column gap-1'>
                    <h4 className=' m-0'>1M+</h4>
                    <p className='hero-description plan-txt-md m-0'>Worldwide Downloads</p>
                  </div>
                </div>
              </div>
              <div className='hero-left-lower d-flex flex-row align-items-center'>
                <div className='hero-count d-flex flex-column align-items-start'>
                  <span className=''>500+</span>
                  <p>Workouts</p>
                </div>
                <div className='hero-count d-flex flex-column align-items-start xborder-card '>
                  <span>1M+</span>
                  <p>Verified Foods</p>
                </div>
                <div className='hero-count d-flex flex-column align-items-start '>
                  <span>1200+</span>
                  <p>Curated Diet Recipes</p>
                </div>
              </div>
            </div>
            <div className='hero-right khatam col-md-12 position-relative'>
              <span className='backShadow'></span>
              <img src='../assets/hero-right-img.png' />
            </div>
          </div>
        </section>
        <section id='introduction-section' className='introduction-sec mt-5rem'>
          <div  className='video-wrapper'>
            <div style={{ position: 'relative', display: 'inline-block' }}>
              {!isPlaying && (
                <div className='thumbnail-wrapper' onClick={handleThumbnailClick}>
                  <img className='intro-thumbnail'
                    src="../assets/intro-thumbnail.jpg" // Path to your thumbnail image
                    alt="Video Thumbnail"
                    style={{width: '100%', cursor: 'pointer' }} 
                  /> 
                </div>             
              )}
              <video
                ref={videoRef}
                style={{ display: isPlaying ? 'block' : 'none', width: '100%', outline : '0' }}
                controls= {true}
                loop= {true}
                controlsList="nodownload"
                disablePictureInPicture
              >
                <source src={videoSrc} type="video/mp4" />
              </video>
            </div>
          </div>
        </section>
        <section id="app-working-section" className='app-working d-flex flex-column mt-6rem'>
          <div className='app-working-title w-50 align-self-center pb-2'>
            <h2 className='hero-sub-heading App'>How it works?</h2>
          </div>
          <div className='app-working-description w-50 align-self-center pb-5'>
            <p className='plan-txt-sm-black App'>MyFitnessCoach meal and diet plans are based on user input and tailored to support your fitness journey.</p>
          </div>
          <VerticalCarousel />
        </section>
        <section id="app-features-section" className='app-features mx-auto d-flex flex-column col-11 d-flex flex-column align-self-center mt-6rem'>
          <div className='app-features-logo d-flex flex-column align-items-center pt-5'>
            <img className='features-logo1' src='../assets/app-features-logo.png' alt='iphone having myfitnesscoach features' />
          </div>
          <div className='app-features-title w-100 align-self-start'>
            <h2 className='hero-sub-heading App text-start m-0'>Our App Features</h2>
          </div>
          <div className='features-card-wraper'>
            <div className='feature-card fc-1 position-relative'>
              <div className='feature-card-logo d-flex'>
                <img className='' src='../assets/af-1.svg' alt='Curated diet plans' />
              </div>
              <h4 className='app-titleh'>Curated Diet Plans</h4>
              <p className='plan-txt-xsm2'>MyFitnessCoach provides curated diet plans according to your macro requirements and user’s daily dietary preferences</p>
            </div>
            <div className='feature-card fc-2 position-relative'>
              <div className='feature-card-logo'>
                <img className='' src='../assets/af-2.svg' alt='macro coach' />
              </div>
              <h4 className='app-titleh'>Macro Coach</h4>
              <p className='plan-txt-xsm2'>MyFitnessCoach generates diet and workout plans based on user’s body type, to make sure each person’s requirements can be met.</p>
            </div>
            <div className='feature-card fc-3 position-relative'>
              <div className='feature-card-logo'>
                <img className='' src='../assets/af-3.svg' alt='Barcode scaner' />
              </div>
              <h4 className='app-titleh'>Barcode Scanner</h4>
              <p className='plan-txt-xsm2'>Keep track of your macronutrients with the My Macro Mate feature, which helps you maintain an optimal balance of carbs, protein, and fat.</p>
            </div>
            <div className='feature-card fc-4 position-relative'>
              <div className='feature-card-logo'>
                <img className='' src='../assets/af-4.svg' alt='Workout Builder' />
              </div>
              <h4 className='app-titleh'>Workout Builder</h4>
              <p className='plan-txt-xsm2'>Build and log your personalized workouts using MyFitnessCoach. Either create your workout by scratch or use our curated templates.</p>
            </div>
            <div className='feature-card fc-5 position-relative'>
              <div className='feature-card-logo'>
                <img className='' src='../assets/af-5.svg' alt='Focused Workouts' />
              </div>
              <h4 className='app-titleh'>Focused Workouts</h4>
              <p className='plan-txt-xsm2'>Designed by fitness experts, these workouts target specific muscle groups and help you get the body you want.</p>
            </div>
            <div className='feature-card fc-6 position-relative'>
              <div className='feature-card-logo'>
                <img className='' src='../assets/af-6.svg' alt='Fitness Tracking' />
              </div>
              <h4 className='app-titleh'>Fitness Tracking</h4>
              <p className='plan-txt-xsm2'>Stay motivated to reach your fitness goals by tracking your daily activities like Daily Steps, Calories burned, Heart Rate etc.</p>
            </div>
            <div className='feature-card fc-7 position-relative'>
              <div className='feature-card-logo'>
                <img className='' src='../assets/af-7.svg' alt='Explore 1200+ Recipes' style={{marginRight:"-5px"}}/>
              </div>
              <h4 className='app-titleh'>Explore 1200+ Recipes</h4>
              <p className='plan-txt-xsm2'>Discover tons of delicious and healthy recipes from quick meals to gourmet dishes with MyFitnessCoach App</p>
            </div>
            <div className='feature-card fc-8 position-relative'>
              <div className='feature-card-logo'>
                <img className='' src='../assets/af-8.svg' alt='Weight Measurement' />
              </div>
              <h4 className='app-titleh'>Weight Measurement</h4>
              <p className='plan-txt-xsm2'>Keep track of your weight trends with MyFitnessCoach. Monitor your progress & make informed decisions.</p>
            </div>
            <div className='feature-card fc-9 position-relative'>
              <div className='feature-card-logo'>
                <img className='' src='../assets/af-9.svg' alt='Heart Rate Monitoring' />
              </div>
              <h4 className='app-titleh'>Heart Rate Monitoring</h4>
              <p className='plan-txt-xsm2'>Monitor how your heart rate changes over the day and get helpful insights about your performance.</p>
            </div>
          </div>
        </section>
        <section id="diet-plans-section" className='diet-plans mt-6rem'>
          <Carousel items={1} section="diet-goals" />
        </section>
        <section id="macro-mate-section" className='macro-mate d-flex flex-column align-items-center mt-6rem'>
          <div className='macro-mate-title w-50 align-self-center pb-2' >
            <h2 className='hero-sub-heading App'>My Macro Coach</h2>
          </div>
          <div className='macro-mate-description w-75 align-self-center'>
            <p className='plan-txt-sm-black App'>Get access to personalized macro calculations, real-time tracking, and flexible meal planning to revolutionize your nutrition. The My Macro Mate system will optimize your macronutrient intake, enhance energy and performance, increase muscle growth, and promote long-term health and wellness.</p>
          </div>
          {/* Row 1 */}
          <div className='mac-row1 mate-content-wraper d-flex align-items-center cg pt-5'>
            <div className='mate-left col-lg-7 col-sm-12 card-radius-bg px-4 pt-4 d-flex flex-column align-items-center mn-height-mate-card'>
              <h3 className='mate-sub-title card-inner-title'>Optimize Your Nutrition Journey</h3>
              <p className='mate-sub-description pt-2 plan-txt-xsm'>Personalized macro calculations tailored to your body composition, activity level, and fitness goals will completely transform your approach to nutrition. Experience the power of precision in fueling your body for maximum performance.</p>
              <div className='mate-body pt-4 position-relative mt-auto'>
                <img className='macro-food1 slide-right' src='assets/nutrition-journey.png' alt='my macro mate feature in my fitnesscoach' />
                <img className='log-food position-absolute slide-left' src='assets/nutrition-journey2.png' />
              </div>
            </div>
            <div className='mate-right col-lg-5 col-sm-12 card-radius-bg px-4 pt-4 d-flex flex-column align-items-center mn-height-mate-card' >
              <h3 className='mate-sub-title2 card-inner-title'>Real-Time Tracking And Insights</h3>
              <p className='mate-sub-description2 pt-2 plan-txt-xsm'>Keep track of your progress, log your meals, and gain valuable insights into your macronutrient breakdown in real time. Keep track of your goals, make informed decisions, and maximize your results with this game-changing tool.</p>
              <div className='mate-body2 pt-4 mt-auto'>
                <img className='slide-top' src='assets/tracking-insight.png' alt='my macro mate in iphone' />
              </div>
            </div>
          </div>
          {/* Row 2 */}
          <div className='mac-row2 mate-content-wraper d-flex align-items-center pt-5'>
            <div className='col-lg-4 col-sm-12 px-2 '>
              <div className='mate-left  card-radius-bg px-4 pt-4 d-flex flex-column align-items-center h89vh'>
                <h3 className='mate-sub-title card-inner-title'>Log Food Through Search</h3>
                <p className='mate-sub-description pt-2 plan-txt-xsm'>With <b>MyMacroCoach,</b> log your intake through multiple options</p>
                <div className='mate-body3 pt-4 position-relative mt-auto'>
                  <img className='slide-right w-100' src='assets/log-food-grp.png' alt='my macro mate feature in my fitnesscoach' />
                </div>
              </div>
            </div>
            <div className='col-lg-4 col-sm-12 px-2 '>
              <div className='mate-right card-radius-bg px-4 pt-4 d-flex flex-column align-items-center h89vh' >
                <h3 className='mate-sub-title2 card-inner-title'>Log Through Barcode</h3>
                <p className='mate-sub-description2 pt-2 plan-txt-xsm'>With a comprehensive barcode scanner, you can log all your intakes with the barcode. Scan and log your intakes!</p>
                <div className='mate-body4 pt-4 pb-4 mt-auto'>
                  <img className='slide-top log-barcode w-100' src='assets/log-barcode.png' alt='my macro mate in iphone' />
                </div>
              </div>
            </div>
            <div className='col-lg-4 col-sm-12 px-2 '>
              <div className='mate-right card-radius-bg px-4 pt-4 d-flex flex-column align-items-center h89vh' >
                <h3 className='mate-sub-title2 card-inner-title'>Create New Recipes or Log Through Quick Calories</h3>
                <p className='mate-sub-description2 pt-2 plan-txt-xsm'>Follow your favorite diets and create your own recipes and log them the way you want.</p>
                <div className='mate-body5 pt-4 mt-auto'>
                  <img className='slide-top w-100' src='assets/log-calories-grp.png' alt='my macro mate in iphone' />

                </div>
              </div>
            </div>
          </div>
        </section>
        <section id='workout-goals-section' className='workout-professionals d-flex flex-column align-items-center mt-6rem position-relative'>
          <div className='wpbp-title w-100 align-self-center pb-2' >
            <h2 className='hero-sub-heading App'>
              <span className='blue-heading-text'>Workouts Performed</span> by Professionals
            </h2>
          </div>
          <div className='wpbp-description w-75 align-self-center'>
            <p className='plan-txt-sm-black App'>MyFitnessCoach can help you find workout routines that match your goals and fitness level. With simple exercises and tailored advice, you can reach your goals and stay motivated to start your journey today!
            </p>
          </div>
          <img className='wpbp-img w-100 mt-4' src='assets/wpbp.png' />
        </section>
        <section id="focused-workouts" className='focused-workouts d-flex align-items-center gap-4 mt-6rem'>
          <div className='focused-left d-flex flex-column gap-2 align-items-start'>
            <div className='fw-title w-100 align-self-center' >
              <h2 className='hero-sub-heading App text-start'>
                <span className='blue-heading-text'>Focused Workouts</span> <br /> for your every need
              </h2>
            </div>
            <div className='fw-description w-100 align-self-center'>
              <p className='plan-txt-sm-black App text-start'>MyFitnessCoach has an expansive repertoire of workouts ranging from beginners to advanced workouts, while providing various comprehensive workouts that are designed specifically to meet certain fitness goals.
              </p>
            </div>
            <div className='program-cards'>
              <div className='program-card'>
                <p>Fat Loss Programs</p>
              </div>
              <div className='program-card'>
                <p>Body Recomposition Programs</p>
              </div>
              <div className='program-card'>
                <p>Muscular Endurance</p>
              </div>
              <div className='program-card'>
                <p>Full-Body Split Programs</p>
              </div>
              <div className='program-card'>
                <p>Strength Training Programs</p>
              </div>
              <div className='program-card'>
                <p>Summer Shred Programs</p>
              </div>
              <div className='program-card'>
                <p>Hypertrophy</p>
              </div>
              <div className='program-card'>
                <p>Home Workouts</p>
              </div>
              <div className='program-card'>
                <p>Muscle Building Programs</p>
              </div>
              <div className='program-card'>
                <p>Body Weight Programs</p>
              </div>
              <div className='program-card'>
                <p>Push, Pull & Legs Workouts</p>
              </div>
              <div className='program-card'>
                <p>Glute Focused Programs</p>
              </div>
            </div>
          </div>
          <div className='focused-right d-flex align-items-center justify-content-center'>
            <img className='w-100' src='assets/focused-img.png' />
          </div>
        </section>
        <section id='yoga-section' className='yog-section d-flex align-items-center flex-column gap-2 mt-6rem'>
          <div className='yoga-title w-100 align-self-center pb-2' >
            <h2 className='hero-sub-heading App'>
              <span className='blue-heading-text'>Unplug, De-Stress</span> & <span className='blue-heading-text'>Recharge </span> <br />
              with MyFitnessCoach
            </h2>
          </div>
          <div className='yoga-description w-75 align-self-center'>
            <p className='plan-txt-sm-black App'>
              MyFitnessCoach not only has an extensive workout library, we also boast to provide different meditation, yoga, Pilates and breathwork exercises.
            </p>
          </div>
          <div className='yoga-body d-flex w-100'>
            <div className='yoga-card-wrapper ps-0'>
              <div className='yoga-card yc1 d-flex flex-column align-items-center'>
                <img src='assets/meditation.png' />
                <p className='yc-desc'>
                  Experience the soothing benefits of meditation with MyFitnessCoach to reduce stress and enhance mindfulness. Our expertly designed meditation practices will help you achieve mental clarity and stay focused on your goals.
                </p>
              </div>
            </div>
            <div className='yoga-card-wrapper ps-0'>
              <div className='yoga-card yc2 d-flex flex-column align-items-center'>
                <img src='assets/breathwork.png' />
                <p className='yc-desc'>
                  Experience various breathwork techniques with MyFitnessCoach that help you reduce stress, increase energy, and enhance mental clarity. Our guided sessions are crafted to help you connect deeply with your body and mind.
                </p>
              </div>
            </div>
            <div className='yoga-card-wrapper ps-0'>
              <div className='yoga-card yc3 d-flex flex-column align-items-center'>
                <img src='assets/yoga.png' />
                <p className='yc-desc'>
                  Embrace the transformative power of yoga with MyFitnessCoach. Our yoga practices are designed to bring harmony to your body and mind, helping you stay centered and focused.
                </p>
              </div>
            </div>
            <div className='yoga-card-wrapper ps-0 pe-0'>
              <div className='yoga-card yc4 d-flex flex-column align-items-center'>
                <img src='assets/pilates.png' />
                <p className='yc-desc'>
                  Discover tailored Pilates routines with MyFitnessCoach to build strength, flexibility, and balance. Get a leaner, more toned body while enhancing your overall well-being with Pilates workouts.
                </p>
              </div>
            </div>
          </div>
        </section>
        <section id='recovery-workouts' className='rec-workouts-section d-flex align-items-center justify-content-between gap-2 mt-6rem'>
              <div className='rws-left'>
                <h2 className='hero-sub-heading pb-3'>
                  Revitalize Your Performance
                  <br/> with Recovery Workouts!
                </h2>
                <p className='plan-txt-sm-black text-start'>
                At MyFitnessCoach, we know that true fitness is more than breaking a sweat—it's about feeling great, moving better, and staying injury-free. That’s why we’re thrilled to announce our Recovery Workouts, featuring prehab, rehab, and mobility programs designed just for you!
                <br/>
                <br/>
                Whether you’re bouncing back from an injury, aiming to prevent one, or simply looking to enhance your flexibility and range of motion, our recovery workouts are here to help. Each program is thoughtfully crafted for specific muscle groups, targeting exactly where you need it most.
                <br/>
                <br/>
                With expert-guided routines and proven techniques, you’ll feel empowered to overcome pain, restore strength, and move with confidence. Say goodbye to discomfort and hello to a stronger, healthier you.
                <br/>
                <br/>
                Ready to redefine recovery? Start your journey today with MyFitnessCoach!
                </p>
              </div>
              <div className='rws-right'>
                <img src='assets/rec-workouts-img.png' />
              </div>
        </section>
        <section id='create-workout-section' className='create-workouts d-flex flex-column align-items-center mt-6rem position-relative'>
          <div className='wpbp-title w-100 align-self-center pb-2' >
            <h2 className='hero-sub-heading App'>
            Create Customized Workouts
            </h2>
          </div>
          <div className='wpbp-description w-75 align-self-center'>
            <p className='plan-txt-sm-black App'>Unleash your fitness potential with MyFitnessCoach! Effortlessly create, customize, and share your unique workout plans with your friends & community. 
            </p>
          </div>
          {/* create custom routines  */}
          <div className='cwc-body'>
            <div className='cwc-left justify-content-start'>
              <h3 className='mate-sub-title card-inner-title'>Create Custom Routines
              </h3>
              <p className='mate-sub-description pt-2 plan-txt-xsm text-justify'>MyfitnessCoach now lets you create your own workout routines. Create your personalized workout and share it with friends and family <br/><br/>
              You can either create your workout from scratch or use one of our workout templates to kickstart the process.
              </p>
            </div>
            <div className='cwc-right justify-content-end'>
              <img src='assets/custom-routine-img.jpg' />
            </div>
          </div>
          {/* edit workouts the way you see fit  */}
          <div className='cwc-body'>
            <div className='cwc-right justify-content-start'>
              <img src='assets/edit-workouts-img.jpg' />
            </div>
            <div className='cwc-left justify-content-end'>
              <h3 className='mate-sub-title card-inner-title'>Edit Workouts the way you see fit
              </h3>
              <p className='mate-sub-description pt-2 plan-txt-xsm text-justify'>Quickly customize your workout by adjusting sets, reps, and weights for each exercise! Organize your routines into Super Sets, Drop Sets, Giant Sets, or Circuits to log them effectively.
              </p>
            </div>        
          </div>
              {/* Log & Monitor Workout Progress  */}
          <div className='cwc-body'>
            <div className='cwc-left justify-content-start'>
              <h3 className='mate-sub-title card-inner-title'>Log & Monitor Workout Progress
              </h3>
              <p className='mate-sub-description pt-2 plan-txt-xsm text-justify'>Track your workout progress with MyFitnessCoach. Easily analyze your performance over weeks and months, and see your improvements. Compare the muscle groups you've targeted this week with those from last week or last month to understand your growth.
              </p>
            </div>
            <div className='cwc-right justify-content-end'>
              <img src='assets/log-monitor-img.jpg' />
            </div>
          </div>
          {/* Share Your Workouts with Friends  */}
          <div className='cwc-body'>
            <div className='cwc-right justify-content-start'>
              <img src='assets/workout-friends-img.jpg' />
            </div>
            <div className='cwc-left justify-content-end'>
              <h3 className='mate-sub-title card-inner-title'>Share Your Workouts with Friends
              </h3>
              <p className='mate-sub-description pt-2 plan-txt-xsm text-justify'>Invite your friends to experience your custom workout routine! If they have MyFitnessCoach, they can easily save it. No app? No problem! They can still check it out in their browser.
              </p>
            </div>        
          </div>
        </section>
        <section id="workout-loging-mate-section" className='macro-mate d-flex flex-column align-items-center mt-6rem'>
          <div className='macro-mate-title w-50 align-self-center pb-2' >
            <h2 className='hero-sub-heading App'>Workout Logging</h2>
          </div>
          <div className='macro-mate-description w-75 align-self-center'>
            <p className='plan-txt-sm-black App'>Track and log workouts with our new Workout Logger to monitor your workout progress effectively.</p>
          </div>
          <div className='mac-row1 mate-content-wraper d-flex align-items-center cg pt-5'>
            <div className='mate-left col-lg-7 col-sm-12 card-radius-bg px-4 pt-4 d-flex flex-column align-items-center mn-height-mate-card overflow-hidden'>
              <h3 className='mate-sub-title card-inner-title'>Log Your Workouts Effortlessly</h3>
              <p className='mate-sub-description pt-2 plan-txt-xsm text-justify'>Track, customize and log your workouts with MyFitnessCoach. Input sets, reps, duration, or weight lifted to customize your logging experience. This tool serves as your fitness trainer, allowing you to monitor your workout progress, analyze performance trends, and stay committed to your goals. This tool is equally beneficial whether you're a fitness expert or a beginner.</p>
              <div className='mate-body pt-4 position-relative mt-auto'>
                <img className='macro-food1 slide-right' src='assets/workout-logging.png' alt='my macro mate feature in my fitnesscoach' />
                <img className='log-food position-absolute slide-left' src='assets/workout-logging-2.png' />
              </div>
            </div>
            <div className='mate-right col-lg-5 col-sm-12 card-radius-bg px-4 pt-4 d-flex flex-column align-items-center mn-height-mate-card' >
              <h3 className='mate-sub-title2 card-inner-title'>Workout Progression</h3>
              <p className='mate-sub-description2 pt-2 plan-txt-xsm'>Track your workout progress effortlessly with the integrated Workout Logger. Record exercises, monitor weekly progress, and celebrate milestones, all while staying motivated on your fitness journey.</p>
              <div className='mate-body2 pt-4 mt-auto'>
                <img className='slide-top' src='assets/workout-progression.png' alt='workout logging in iphone' />
              </div>
            </div>
          </div>
        </section>
        <section id='track-fitness' className='track-fitness overflow-visible d-flex gap-4 align-items-center mt-6rem'>
          <div className='tf-left position-relative'>
            <img src='assets/track-fitness.png' />
            <img className='pulse' src='assets/pulse.png' />
          </div>
          <div className='tf-right'>
            <div className='fw-title w-100 align-self-center' >
              <h2 className='hero-sub-heading App text-start'>
                <span className='blue-heading-text'>Sync Wearables &  </span> <br />Track Fitness Journey
              </h2>
            </div>
            <p className='plan-txt-sm-black text-start'>
              Transform your fitness journey by syncing your wearables with MyFitnessCoach. Our platform not only consolidates all your fitness data but also delivers personalized trends and prompts that accurately reflect your health status. Experience a comprehensive and insightful approach to fitness management with MyFitnessCoach.
            </p>
            <h5 className='card-inner-title'>
              Information We Track:
            </h5>
            <div className='tf-ul-wrapper'>
              <ul>
                <li>Daily Steps</li>
                <li>Calories</li>
                <li>Heart Rate</li>
                <li>Distance Travelled</li>
              </ul>
              <ul className='ps-7'>
                <li>Sleep Schedule</li>
                <li>Stress Management</li>
                <li>Blood Oxygen</li>
                <li>Respiratory Rate</li>
              </ul>
            </div>
          </div>
        </section>
        <section id='integrated-devices' className='integrated-devices d-flex flex-column gap-2 align-items-center mt-6rem'>
          <h4 className='id-title'>Integrated Devices</h4>
          <p className='id-desc'>We have successfully integrated wearables using the following apps, and are striving to integrate more soon:</p>
          <div className='id-brands d-flex align-items-center justify-content-between flex-wrap w-100 gap-1'>
            <img src='assets/polar-logo.png' />
            <div className='id-separator'></div>
            <img src='assets/garmin-logo.png' />
            <div className='id-separator'></div>
            <img src='assets/fitness+-logo.png' />
            <div className='id-separator'></div>
            <img src='assets/googleFit-logo.png' />
            <div className='id-separator'></div>
            <img src='assets/fitbit-logo.png' />
          </div>
        </section>
        <section id='fitness-tracking' className='fitness-tracking d-flex flex-column gap-3 align-items-center mt-6rem'>
          <div className='ft-title'>
            <p>Fitness Tracking</p>
          </div>
          <CustomAccordian></CustomAccordian>
        </section>
        <section id="body-insights-mate-section" className='macro-mate d-flex flex-column align-items-center mt-6rem'>
          <div className='macro-mate-title w-50 align-self-center pb-2' >
            <h2 className='hero-sub-heading App'>Body Insights</h2>
          </div>
          <div className='macro-mate-description w-75 align-self-center'>
            <p className='plan-txt-sm-black App'>Track changes in your body with MyFitnessCoach's new tool. Set personalized goals and work towards your desired body. Start monitoring your progress now!</p>
          </div>
          <div className='mac-row1 mate-content-wraper d-flex align-items-center cg pt-5'>
            <div className='mate-left col-lg-5 col-sm-12 card-radius-bg px-4 pt-4 d-flex flex-column align-items-center mn-height-mate-card' >
              <h3 className='mate-sub-title2 card-inner-title'>Log your data</h3>
              <p className='mate-sub-description2 pt-2 plan-txt-xsm'>Customize your fitness journey with accurate measurements for arms, legs, waist, neck, shoulders, and hips. Track your real-time progress through graphs, visually mapping your body transformation over days, weeks, and months. Achieve your body goals with a personalized, data-driven approach.</p>
              <div className='mate-body2 pt-4 mt-auto'>
                <img className='slide-top' src='assets/body-insights-log-data.png' alt='my macro mate in iphone' />
              </div>
            </div>
            <div className='mate-right col-lg-7 col-sm-12 card-radius-bg px-4 pt-4 d-flex flex-column align-items-center mn-height-mate-card'>
              <h3 className='mate-sub-title card-inner-title'>Monitor your progress - Make informed decisions</h3>
              <p className='mate-sub-description pt-2 plan-txt-xsm'>Elevate your fitness game with our Body Insights tool – it's more than just numbers; it's your body's exciting story! Set thrilling goals, track progress with clear graphs, and take control of your fitness journey. It's like having a personal coach in your pocket – simple yet powerful.</p>
              <div className='w-100 pt-4 position-relative mt-auto'>
                <img className='macro-food2 slide-right w-100' src='assets/monitor-progress.png' alt='my macro mate feature in my fitnesscoach' />
              </div>
            </div>
          </div>
        </section>
        <section id="weight-management-mate-section" className='macro-mate d-flex flex-column align-items-center mt-6rem'>
          <div className='macro-mate-title w-50 align-self-center pb-2' >
            <h2 className='hero-sub-heading App'>Weight Management</h2>
          </div>
          <div className='macro-mate-description w-75 align-self-center'>
            <p className='plan-txt-sm-black App'>Streamline your weight management with MyFitnessCoach. Easily log progress, track trends, and make informed choices for a healthier lifestyle.</p>
          </div>
          <div className='mac-row1 mate-content-wraper d-flex align-items-center cg pt-5'>
            <div className='mate-left col-lg-7 col-sm-12 card-radius-bg px-4 pt-4 d-flex flex-column align-items-center mn-height-mate-card overflow-hidden'>
              <h3 className='mate-sub-title card-inner-title'>Keep track of your weight changes</h3>
              <p className='mate-sub-description pt-2 plan-txt-xsm text-justify'>Track your weight with MyFitnessCoach for detailed graphs. Regularly log your weight to see how your lifestyle affects your fitness journey. Monitor progress and make informed decisions to enhance your fitness.</p>
              <div className='w-100 pt-4 position-relative mt-auto'>
                <img className='slide-right w-100 track-weight' src='assets/track-weight.png' alt='my macro mate feature in my fitnesscoach' />
              </div>
            </div>
            <div className='mate-right col-lg-5 col-sm-12 card-radius-bg px-4 pt-4 d-flex flex-column align-items-center mn-height-mate-card' >
              <h3 className='mate-sub-title2 card-inner-title'>Log Your Data </h3>
              <p className='mate-sub-description2 pt-2 plan-txt-xsm'>Effortlessly log your weight and monitor your progress weekly, monthly, or yearly. Evaluate your commitment to goal weight with regular logging.</p>
              <div className='mate-body2 pt-4 mt-auto'>
                <img className='slide-top' src='assets/body-insights-log-data.png' alt='my macro mate in iphone' />
              </div>
            </div>
          </div>
        </section>
        <section id="testimonial-section" className='testimonial mt-6rem'>
          <div className='testimonial-head'>
            <div className='th-left'>
              <h5 className='m-0'>Testimonials</h5>
              <h2 className='hero-sub-heading m-0'>What Our Users Say</h2>
            </div>
            {/* <div className='th-right'>
              <button className='prev-btn'>
                <svg width="10" height="18" viewBox="0 0 10 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M8.85693 16.7137L1.14265 8.99944L8.85693 1.28516" stroke="#1B2B4B" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
              </button>
              <button className='next-btn'>
                <svg width="10" height="18" viewBox="0 0 10 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M1.14307 16.7137L8.85735 8.99944L1.14307 1.28516" stroke="#1B2B4B" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
              </button>
            </div> */}
          </div>
          <Carousel section="testimonial" items={1} nav={true} dots={false} swipeDelta={10} />
        </section>
        <section id="info-videos-section" className='info-videos  mt-6rem p-1'>
          <div className='info-vid-head d-flex flex-wrap'>
            <div className='info-vid-title w-100'>
              <h2 className='hero-sub-heading text-center'>Informatory Videos</h2>
            </div>
            {/* <div className='info-vid-link w-50 d-flex justify-content-end'>
              <a className='subscribe-paragraph'>See More at MyFitnessCoach</a>
            </div> */}
            <div className='info-vid-description'>
              <p className='plan-txt-sm-black'>Get access to a library of expert-led videos that provide valuable insights, tips, and guidance on a variety of fitness topics. Become an expert, stay motivated, and make smart decisions to reach your goals.</p>
            </div>
          </div>
          <div className='info-vid-main'>
            <div className='info-vid-left'>
              <div className='intro-right intro-right-2 d-flex justify-content-center align-items-center'>
                <div style={{ position: "relative", width: "80%" }}>
                  <img src="assets/mobile-frame.webp" className="" style={{ height: "100%", width: "100%" }} alt="iphone frame" />
                  <img src="assets/camera-lense.svg" className='camera-lense' alt='iphone camera lense' />
                  <video className="video-fluid informative-video intro-video-2" src="assets/compressed-video.mp4" type="video/mp4" alt="Fitness Trainer"
                    preload="none" controls={true} autoPlay={true} muted={true} playsInline={false} loop={true}
                  />
                </div>
              </div>
            </div>
            <div className='info-vid-right'>
              <div className='ques d-flex'>
                <h3 className='ques-p'>How to lose body fat?</h3>
                <span>&gt;</span>
              </div>
              <div className='ques d-flex'>
                <h3 className='ques-p'>What is Body Fat Percentage</h3>
                <span>&gt;</span>
              </div>
              <div className='ques d-flex'>
                <h3 className='ques-p'>Importance of Rest Days</h3>
                <span>&gt;</span>
              </div>
              <div className='ques d-flex'>
                <h3 className='ques-p'>What is Metabolism?</h3>
                <span>&gt;</span>
              </div>
              <div className='ques d-flex'>
                <h3 className='ques-p'>How to improve your 1Rep Max?</h3>
                <span>&gt;</span>
              </div>
              <div className='ques d-flex border-removed'>
                <h3 className='ques-p'>What is Overtraining?</h3>
                <span>&gt;</span>
              </div>
            </div>
          </div>
        </section>
        <section id="subscription-section" className='subscription d-flex align-items-center mt-6rem p-1'>
          <div className='subs-left  d-flex flex-column rg'>
            <h3 className='hero-sub-heading'>Subscribe to Never<br /> Miss Any Update</h3>
            <p className='subscribe-paragraph'>Get the latest info and stay on top! Become a subscriber and get access to exclusive content. Don't miss out, join now.</p>
            <SendEmail />
          </div>
          <div className='subs-right d-flex justify-content-center'>
            <img src='../assets/envelope-email.webp' alt='envelope icon used for subscription' />
          </div>
        </section>
      </div>
    </>
  )
}

export default Home